import { Box } from '@mui/material';
import { getExcelBlobForAllClients, getToken } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Loading, MainMenuTabs } from 'components';
import {
  clientSlice,
  createClientAsync,
  fetchClientsAsync,
  fetchFamiliesAsync,
  removeClientByIdAsync,
  replaceClientByIdAsync,
  selectClientError,
  selectClientMaxPortfoliohNumber,
  selectClients,
  selectClientsStatus,
  selectClientStatus,
  selectFamilies,
  selectFamiliesStatus,
} from 'features';
import { useMember, useToggle } from 'hooks';
import React, { useEffect, useState } from 'react';
import { ClientForClientsPage, DraftClient, NewApiClient } from 'types';
import { AddClientDialog } from '../components/AddClientDialog';
import { ClientsMenu } from '../components/Client/ClientsMenu';
import { ClientsTable } from '../components/Client/ClientsTable';
import { DeleteClientDialog } from '../components/DeleteClientDialog';
import { EditClientDialog } from '../components/Client/EditClientDialog';

export const ClientsPage = () => {
  const [downloadExcelStatus, setDownloadExcelStatus] = React.useState<
    'idle' | 'pending' | 'succeeded' | 'rejected'
  >('idle');
  const [downloadExcelErrorMessage, setDownloadExcelErrorMessage] =
    React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectClients);
  const clientsStatus = useAppSelector(selectClientsStatus);
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const clientMaxPortfoliohNumber = useAppSelector(
    selectClientMaxPortfoliohNumber
  );
  const familiesStatus = useAppSelector(selectFamiliesStatus);
  const families = useAppSelector(selectFamilies);
  const [
    { members: mentors, status: memberStatus, error: memberError },
    { fetchedMembersByRole },
  ] = useMember();
  const [selectedClient, setSelectedClient] = useState<NewApiClient | null>(
    null
  );
  const [isOpenEditDialog, toggleIsOpenEditDialog] = useToggle(false);
  const [isOpenCreateDialog, toggleIsOpenCreateDialog] = useToggle(false);
  const [isOpenDeleteDialog, toggleIsOpenDeleteDialog] = useToggle(false);

  const [searchClientQuery, setSearchClientQuery] = useState<string>('');

  // fetch once
  useEffect(() => {
    if (clientsStatus === 'idle') {
      dispatch(fetchClientsAsync());
    }
  }, [dispatch, clientsStatus]);
  useEffect(() => {
    if (familiesStatus === 'idle') {
      dispatch(fetchFamiliesAsync());
    }
  }, [dispatch, familiesStatus]);

  const handleSearchClientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchClientQuery(event.target.value);
  };

  const clientWithFamilyNameMentorName: ClientForClientsPage[] = (
    clients ?? []
  ).map((client: NewApiClient): ClientForClientsPage => {
    let familyName = '';
    if (client.familyId) {
      switch (familiesStatus) {
        case 'pending':
          familyName = 'Loading...';
          break;
        case 'rejected':
          familyName = '読み込みに失敗しました';
          break;
        case 'succeeded':
          familyName =
            families.find(family => family.id === client.familyId)?.name ?? '';
          break;
        default:
          break;
      }
    }

    return {
      ...client,
      familyName,
      mentorName:
        mentors.find(mentor => mentor.id === client.mentoredBy)?.fullName || '',
    };
  });

  useEffect(() => {
    if (memberStatus === 'idle') {
      fetchedMembersByRole('mentor');
    }
  }, [fetchedMembersByRole, memberStatus]);

  if (clientsStatus === 'pending') {
    return <Loading />;
  }

  const handleEdit = async (formState: DraftClient) => {
    if (selectedClient) {
      try {
        dispatch(
          replaceClientByIdAsync({ id: selectedClient.id, data: formState })
        );
      } catch (e) {
        console.log('Error updating client:', e);
        throw e;
      }
    } else {
      console.log('No client selected.');
    }
  };

  const handleCloseCreateDialog = () => {
    toggleIsOpenCreateDialog();
  };

  const handleCloseEditDialog = () => {
    toggleIsOpenEditDialog();
  };

  const handleCloseDeleteDialog = () => {
    toggleIsOpenDeleteDialog();
  };

  return (
    <>
      <MainMenuTabs tabValue="/clients" />
      <Box sx={{ px: 4, py: 1 }}>
        <ClientsMenu
          downloadExcel={() =>
            (async () => {
              try {
                setDownloadExcelStatus('pending');
                const token = await getToken();
                const blob = await getExcelBlobForAllClients({
                  token,
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Pragmaworks銘柄一覧-全顧客-${new Date().toJSON().slice(0, 10)}.xlsx`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setDownloadExcelStatus('succeeded');
              } catch (e) {
                setDownloadExcelStatus('rejected');
                setDownloadExcelErrorMessage(
                  'Excelのダウンロードに失敗しました'
                );
              }
            })()
          }
          toggleAddClientDialog={toggleIsOpenCreateDialog}
          toggleEditClientDialog={toggleIsOpenEditDialog}
          toggleDeleteClientDialog={toggleIsOpenDeleteDialog}
          isClientSelected={selectedClient !== null}
          downloadExcelStatus={downloadExcelStatus}
          downloadExcelErrorMessage={downloadExcelErrorMessage}
          clientSearchQuery={searchClientQuery}
          onChangeClientSearchQuery={handleSearchClientChange}
        />
        <ClientsTable
          clients={clientWithFamilyNameMentorName.filter(
            client =>
              client.name
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.familyName ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.note ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.clientBillingId ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.mentorName ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase())
          )}
          families={families}
          mentors={mentors}
          selectedClient={selectedClient}
          onSelectionChangeRequest={target => {
            setSelectedClient(current =>
              !current || current.id !== target.id ? target : null
            );
          }}
        />
        {families !== undefined && (
          <AddClientDialog
            key={clientMaxPortfoliohNumber}
            open={isOpenCreateDialog}
            onClose={handleCloseCreateDialog}
            familyOptions={families}
            maxPortpholiohIdNumber={clientMaxPortfoliohNumber ?? 0}
            createClient={clientPayload => {
              dispatch(createClientAsync(clientPayload))
                .unwrap()
                .then(_client => {
                  handleCloseCreateDialog();
                });
            }}
            status={clientStatus}
            error={clientError}
          />
        )}
        {selectedClient && families !== undefined && (
          <EditClientDialog
            open={isOpenEditDialog}
            onClose={handleCloseEditDialog}
            client={selectedClient}
            families={families}
            mentors={mentors}
            onSave={handleEdit}
            status={clientStatus}
            error={clientError}
            key={selectedClient.id}
          />
        )}
        {selectedClient && (
          <DeleteClientDialog
            open={isOpenDeleteDialog}
            onClose={() => {
              handleCloseDeleteDialog();
              dispatch(clientSlice.actions.dismissError());
            }}
            name={selectedClient.name}
            deleteClient={() => {
              return dispatch(removeClientByIdAsync({ id: selectedClient.id }))
                .unwrap()
                .then(() => {
                  handleCloseDeleteDialog();
                });
            }}
            status={clientStatus}
            error={clientError}
          />
        )}
      </Box>
    </>
  );
};
